<template>
  <div class="mod-user">
    <!-- 获取服务客户/总订单/合作加盟车队/自营+协外车辆/今日订单 -->
    <div class="e_summary_box">
      <div class="e_box_item" v-for="(item, idx) in boxContent" :key="idx" :style="'background:' + item.bgColor">
        <div class="e_content" >
          <span class="e_count"><strong>{{item.count}}</strong>{{item.unit}}</span>
          <span class="e_count">{{item.type}}</span>
          <div class="img">
            <icon-svg :name="item.svgUrl"></icon-svg>
          </div>
        </div>
        <div class="e_open_detail"></div>
      </div>
    </div>
    <!-- 今日车辆出勤率情况 -->
    <div class="e_echarts_box">
      <div class="e_top_area">
        <div class="e_car_situation">
          <div class="e_echarts_title">
            <icon-svg name="gongjiaoche"></icon-svg>
            今日车辆出勤率情况
          </div>
          <div class="e_echarts_content e_echarts_first">
            <div class="e_echarts_picture" id="first_echart">
            </div>
            <div class="e_echarts_data">
              <div class="e_echarts_data_item">
                <strong>{{situation.total}}</strong>
                <span>总车辆数(自营+挂靠)</span>
              </div>
              <div class="e_echarts_data_item">
                <strong>{{situation.used}}</strong>
                <span>已使用</span>
              </div>
              <div class="e_echarts_data_item">
                <strong>{{situation.free}}</strong>
                <span>闲置</span>
              </div>
            </div>
          </div>
        </div>
        <div class="e_car_situation">
          <div class="e_echarts_title">
            <icon-svg name="wancheng"></icon-svg>
            今日订单完成情况
          </div>
          <div class="e_echarts_content e_echarts_second">
            <div class="e_echarts_picture" id="second_echart">
            </div>
          </div>
        </div>
      </div>
      <div class="e_mid_area">
        <div class="e_car_situation">
          <div class="e_echarts_title">
            <icon-svg name="dingdanqushi"></icon-svg>
            月度订单增长趋势
            <div class="search">
               <el-date-picker
                v-model="orderYear"
                type="year"
                @change="monthlyBill('three_echart')"
                placeholder="选择年">
              </el-date-picker>
            </div>
          </div>
          <div class="e_echarts_content e_echarts_three">
            <div class="e_echarts_picture" id="three_echart">
            </div>
            <div class="e_echarts_data">
              <div class="e_echarts_data_item">
                <div class="img">
                  <icon-svg name="gongdanliang"></icon-svg>
                </div>
                <strong>{{monthOrder.total}}</strong>
                <span>总订单</span>
              </div>
              <div class="e_echarts_data_item">
                <div class="img">
                  <icon-svg name="zhuxingtu1"></icon-svg>
                </div>
                <strong>{{monthOrder.mTotal}}</strong>
                <span>月平均订单</span>
              </div>
              <div class="e_echarts_data_item">
                <div class="img">
                  <icon-svg name="fangzi"></icon-svg>
                </div>
                <strong>{{monthOrder.orderCustomer}}</strong>
                <span>订单客户数</span>
              </div>
            </div>
          </div>
        </div>
        <div class="e_car_situation">
          <div class="e_echarts_title">
            <icon-svg name="dingdanqushi"></icon-svg>
            本月订单变化趋势
            <div class="search">
               <el-date-picker
                v-model="orderMonth"
                type="month"
                @change="monthChange('four_echart')"
                placeholder="选择月">
              </el-date-picker>
            </div>
          </div>
          <div class="e_echarts_content e_echarts_four">
            <div class="e_echarts_picture" id="four_echart">
            </div>
          </div>
        </div>
      </div>
      <div class="e_bottom_area">
        <div class="e_car_situation">
          <div class="e_echarts_title">
            <icon-svg name="dingdanqushi"></icon-svg>
            【Top10】客户订单排名(总订单)
            <div class="search">
              <el-date-picker
                v-model="rankYear"
                type="year"
                @change="top10('five_echart')"
                placeholder="选择年">
              </el-date-picker>
            </div>
          </div>
          <div class="e_echarts_content e_echarts_five">
            <div class="e_echarts_picture" id="five_echart">
            </div>
          </div>
        </div>
        <div class="e_car_situation">
          <div class="e_echarts_title">
            <icon-svg name="dingdanqushi"></icon-svg>
            【本月Top10】客户订单排名(月订单-大客户)
            <div class="search">
               <el-date-picker
                v-model="rankMonth"
                type="month"
                @change="top10Month('six_echart')"
                placeholder="选择月">
              </el-date-picker>
            </div>
          </div>
          <div class="e_echarts_content e_echarts_six">
            <div class="e_echarts_picture" id="six_echart">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './_echart.less'
export default {
  data () {
    return {
      options: [],
      orderYear: '',
      orderMonth: '',
      timer: null,
      // 获取服务客户/总订单/合作加盟车队/自营+协外车辆/今日订单
      boxContent: [{
        count: 0,
        unit: '家',
        type: '服务客户',
        svgUrl: 'gouwudai',
        bgColor: 'rgb(0, 192, 239)'
      }, {
        count: 0,
        unit: '单',
        type: '总订单',
        svgUrl: 'zhuxingtu1',
        bgColor: 'rgb(0, 160, 90)'
      }, {
        count: 0,
        unit: '家',
        type: '合作加盟车队',
        svgUrl: 'jiamengshang',
        bgColor: 'rgb(243, 156, 18)'
      }, {
        count: '0 + 0',
        unit: '',
        type: '自营 + 外协车辆',
        svgUrl: 'tongjitu',
        bgColor: 'rgb(221, 75, 57)'
      }, {
        count: 0,
        unit: '',
        type: '今日订单',
        svgUrl: 'layer',
        bgColor: 'rgb(0, 192, 239)'
      }],
      // 今日车辆出勤率情况
      dataList: [],
      // 今日车辆出勤率情况
      situation: {
        total: 0, // 总车辆数
        used: 0, // 已使用
        free: 0 // 闲置
      },
      EnterpriseUsers: {},
      monthlyBillData: {},
      monthOrder: {
        total: 0,
        mTotal: 0,
        orderCustomer: 0
      },
      thisMonthOrder: {},
      fristEchart: '',
      secondEchart: '',
      threeEchart: '',
      fourEchart: '',
      top10Data: {},
      rankYear: '',
      rankMonth: ''
    }
  },
  methods: {
    // 今日车辆出勤情况
    drawPie (idDom) {
      let that = this
      this.$http({
        url: this.$http.adornUrl('/home/toDayOutCar'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        that.dataList = [
          { value: data.orderNum, name: '已出车' },
          { value: data.noOutNum, name: '未出车' }
        ]
        that.fristEchart = that.$echarts.init(document.getElementById(idDom))
        that.fristEchart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          series: [
            {
              name: '车辆出勤',
              type: 'pie',
              radius: '70%',
              center: ['45%', '50%'],
              data: that.dataList,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        })
        // 总车辆数
        that.situation.total = data.total
        // 已使用
        that.situation.used = data.orderNum
        // 闲置
        that.situation.free = data.noOutNum
      })
    },
    // 新增企业用户
    addEnterpriseUsers (idDom) {
      let that = this
      this.$http({
        url: this.$http.adornUrl('/home/toDayOrderCount'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // 新增企业用户
        that.EnterpriseUsers = {
          xAxisData: data.labels,
          seriesData: data.values
        }
        that.secondEchart = that.$echarts.init(document.getElementById(idDom))
        that.secondEchart.setOption({
          color: ['#3398DB'],
          title: {
            text: '',
            left: 'center'
          },
          tooltip: {
            trigger: 'axis',
            formatter: '{b}: {c}单',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: that.EnterpriseUsers.xAxisData,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '今日订单完成情况',
              type: 'bar',
              barWidth: '20%',
              data: that.EnterpriseUsers.seriesData
            }
          ]
        })
      })
    },
    // 月度订单增长趋势
    monthlyBill (idDom) {
      let that = this
      this.$http({
        url: this.$http.adornUrl('/home/monthOrderCount'),
        method: 'get',
        params: this.$http.adornParams({
          year: new Date(this.orderYear).getFullYear()
        })
      }).then(({ data }) => {
        that.monthlyBillData = {
          xAxisData: data.labels,
          seriesData: data.values
        }
        that.threeEchart = that.$echarts.init(document.getElementById(idDom))
        that.threeEchart.setOption({
          tooltip: {
            trigger: 'axis',
            formatter: '{b}月 <br/> {c}单'
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: that.monthlyBillData.xAxisData
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: that.monthlyBillData.seriesData,
            type: 'line',
            areaStyle: {}
          }]
        })
        // 总订单
        that.monthOrder.total = data.total
        // 月平均订单
        that.monthOrder.mTotal = data.monthTotal
        // 订单客户数
        that.monthOrder.orderCustomer = data.customerTotal
      })
    },
    // 本月订单变化趋势
    monthChange (idDom) {
      this.$http({
        url: this.$http.adornUrl('/home/dayOrderCount'),
        method: 'get',
        params: this.$http.adornParams({
          yearMonth: this.getDataMonth(this.orderMonth)
        })
      }).then(({ data }) => {
        this.thisMonthOrder = {
          xAxisName: data.labels,
          seriesData: data.values
        }
        this.fourEchart = this.$echarts.init(document.getElementById(idDom))
        this.fourEchart.setOption({
          tooltip: {
            trigger: 'axis',
            formatter: '{b}日 <br/> {c}单'
          },
          xAxis: {
            type: 'category',
            data: this.thisMonthOrder.xAxisName
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: this.thisMonthOrder.seriesData,
            type: 'line',
            smooth: true
          }]
        })
      })
    },
    top10 (idDom) {
      this.$http({
        url: this.$http.adornUrl('/home/customerOrderCount'),
        method: 'get',
        params: this.$http.adornParams({
          year: new Date(this.rankYear).getFullYear()
        })
      }).then(({ data }) => {
        this.top10Data = {
          yAxisName: data.labels,
          seriesData: data.values
        }
        this.fourEchart = this.$echarts.init(document.getElementById(idDom))
        this.fourEchart.setOption({
          color: ['#00c0ef'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value'
            }
          ],
          yAxis: [
            {
              type: 'category',
              data: this.top10Data.yAxisName,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          series: [
            {
              name: '单量',
              type: 'bar',
              barWidth: '60%',
              label: {
                show: true,
                position: 'right'
              },
              data: this.top10Data.seriesData
            }
          ]
        })
      })
    },
    top10Month (idDom) {
      this.$http({
        url: this.$http.adornUrl('/home/monthCustomerOrderCount'),
        method: 'get',
        params: this.$http.adornParams({
          yearMonth: this.getDataMonth(this.rankMonth)
        })
      }).then(({ data }) => {
        this.top10Data = {
          yAxisName: data.labels,
          seriesData: data.values
        }
        this.fourEchart = this.$echarts.init(document.getElementById(idDom))
        this.fourEchart.setOption({
          color: ['#ff9900'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value'
            }
          ],
          yAxis: [
            {
              type: 'category',
              data: this.top10Data.yAxisName,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          series: [
            {
              name: '单量',
              type: 'bar',
              barWidth: '60%',
              label: {
                show: true,
                position: 'right'
              },
              data: this.top10Data.seriesData
            }
          ]
        })
      })
    },
    getDataMonth (time) {
      return (new Date(time).getFullYear() + '-' + (new Date(time).getMonth() >= 9 ? (new Date(time).getMonth() + 1) : '0' + (new Date(time).getMonth() + 1)))
    },
    getEcharts () {
      // 今日车辆出勤情况
      this.drawPie('first_echart')
      // 新增企业用户
      this.addEnterpriseUsers('second_echart')
      // 月度订单增长趋势
      this.monthlyBill('three_echart')
      // 本月订单变化趋势
      this.monthChange('four_echart')
      // [top10]客户订单排名(总订单)
      this.top10('five_echart')
      // [本月top10]客户订单排名(月订单)
      this.top10Month('six_echart')
    },
    // 获取服务客户/总订单/合作加盟车队/自营+协外车辆/今日订单
    getCardData () {
      this.$http({
        url: this.$http.adornUrl('/home/topStatistics'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // 服务客户
        this.boxContent[0].count = data.customerNum
        // 总订单
        this.boxContent[1].count = data.orderTotal
        // 合作加盟车队
        this.boxContent[2].count = data.motorcadeNum
        // 自营 + 外协车队
        this.boxContent[3].count = data.motorcadeZy + '+' + data.motorcadeQt
        // 今日订单
        this.boxContent[4].count = data.orderTodayCount
      })
    },
    setDefaultDate () {
      this.orderYear = new Date().getFullYear() + ''
      this.orderMonth = new Date()
      this.rankYear = new Date().getFullYear() + ''
      this.rankMonth = new Date()
    }
  },
  mounted () {
    // 获取服务客户/总订单/合作加盟车队/自营+协外车辆/今日订单
    this.getCardData()
    this.setDefaultDate()
    // 加载图表
    this.$nextTick(function () {
      this.getEcharts()
    })
  },
  created () {
    if (this.timer === null) {
      this.timer = setInterval(() => {
        this.getCardData()
        this.getEcharts()
      }, 30 * 1000)
    }
  },
  beforeDestroy () {
    if (this.timer != null) {
      clearInterval(this.timer)
    }
  }

}
</script>
